<template>
  <div class="findpwd-view">
    <div class="findpwd-wraper">
      <div class="findpwd-title">找回密码</div>

      <div class="findpwd-type-group" v-if="formStep == 1">
        <div style="margin-top: 64px">请选择找回密码的企业类型</div>
        <div class="company-type-list">
          <div
            class="company-type-item"
            v-for="item in companyTypes"
            :key="item.id"
            @click="selectCompanyType(item)"
          >
            <span>{{ item.name }}</span>
          </div>
        </div>

        <div class="no-account-row">
          没有账号,
          <el-link
            type="primary"
            :underline="false"
            @click="$router.push('/register')"
            >立即注册</el-link
          >
        </div>
      </div>

      <el-form
        class="findpwd-form"
        label-position="left"
        label-width="125px"
        v-if="formStep == 2"
      >
        <el-form-item label="手机号码">
          <el-input
            v-model="form.phone"
            class="form-input"
            placeholder="请输入手机号码"
          ></el-input>
        </el-form-item>
        <el-form-item label="手机验证码">
          <div class="code-row">
            <el-input
              v-model="form.message"
              class="form-input"
              style="margin-right: 20px"
              placeholder="请输入验证码"
            ></el-input>
            <el-button
              v-if="sendtimeout == 0"
              type="primary"
              class="code-btn"
              @click="sendMessage"
              >获取验证码</el-button
            >
            <el-button v-else disabled>{{ sendtimeout }}秒后重新发送</el-button>
          </div>

          <div class="code-tip-row">
            原绑定手机号码不可用？点击
            <a
              href="javascript:void(0)"
              class="link"
              @click="$router.push('/resetbind')"
              >重置绑定账号</a
            >
          </div>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input
            v-model="form.pwd"
            class="form-input"
            type="password"
            placeholder="请输入新密码"
          ></el-input>
        </el-form-item>

        <div class="form-action-row">
          <el-button type="primary" @click="findPassword">确定</el-button>
          <el-button type="default" @click="cancel">取消</el-button>
        </div>

        <div class="no-account-tip-row">
          没有账号，
          <a class="link" @click="$router.push('/register')">立即注册</a>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
const companyTypes = [
  {
    id: 0,
    name: "集成类公司",
  },
  {
    id: 1,
    name: "供应商公司",
  },
  {
    id: 2,
    name: "招标方公司",
  },
];
export default {
  data() {
    return {
      form: {
        type: "",
        phone: "",
        message: "",
        pwd: "",
      },

      timer: null,
      sendtimeout: 0,

      formStep: 1,
    };
  },
  created(){
    console.log(window);
  },
  computed: {
    companyTypes() {
      return companyTypes;
    },
  },
  methods: {
    selectCompanyType(item) {
      this.form.type = item.id;

      this.formStep = 2;
    },
    sendMessage() {
      if (this.form.phone == "") {
        alert("请输入手机号");
        return;
      }
      this.$axios
        // .post(`/sms/sendRetrievePwdMessage/${this.form.phone}`, {
        //   phone: this.form.phone,
        //   type: 0,
        // })
        .post(`/sms/sendRetrievePwdMessage/${this.form.phone}/${this.form.type}`)
        .then((res) => {
          if (res.state == 200) {
            this.sendtimeout = 60;
            this.timer = setInterval(() => {
              if (this.sendtimeout > 0) {
                this.sendtimeout--;
              } else {
                clearInterval(this.timer);
                this.sendtimeout = 0;
              }
            }, 1000);
          } else {
            alert(res.message);
          }
        });
    },
    findPassword() {
      if (this.form.phone == "") {
        alert("请输入手机号");
        return;
      }
      if (this.form.message == "") {
        alert("请输入验证码");
        return;
      }
      this.$axios
        .post(`/member/retrievePassword/${this.form.type}/${this.form.phone}/${this.form.message}?pwd=${this.form.pwd}`)
        // .post(
        //   `/member/retrievePassword/${this.form.type}/${this.form.phone}/${this.form.message}/${this.form.pwd}`
        // )
        .then((res) => {
          if (res.state == 200) {
            alert(res.message);
            this.$router.push("/login");
          } else {
            alert(res.message);
          }
        });
    },
    cancel() {
      this.formStep = 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.findpwd-view {
  background: #f9f9f9;
  padding: 182px 0 114px 0;
  .findpwd-wraper {
    width: 1211px;
    height: 630px;
    background: #ffffff;
    border: 1px solid #ededed;
    margin: 0 auto;
    padding: 64px 0;
    box-sizing: border-box;
    .findpwd-title {
      color: #333333;
      line-height: 40px;
      letter-spacing: 1px;
      font-size: 28px;
      text-align: center;
      margin-bottom: 64px;
    }
    .findpwd-type-group {
      padding: 0 200px;
      font-size: 20px;
      .company-type-list {
        margin-top: 48px;
        display: flex;
        .company-type-item {
          width: 263px;
          height: 123px;
          background: #ffffff;

          border: 1px solid #ededed;

          color: #263238;
          font-size: 20px;
          margin-right: 13px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 0px 36px 4px rgba(56, 131, 255, 0.15);
          }
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
      .no-account-row {
        margin-top: 82px;
        font-size: 18px;
        a {
          font-size: 18px;
        }
      }
    }

    .findpwd-form {
      width: 530px;
      margin: 0 auto;
      .el-form-item {
        margin-bottom: 30px;
      }
      .form-input {
        width: 225px;
      }
      .code-row {
        display: flex;
        .code-btn {
          margin-left: 24px;
        }
      }
      .code-tip-row {
        margin-top: 22px;
        color: #999999;
        line-height: 22px;
        font-size: 16px;
      }

      .form-action-row {
        text-align: center;
        margin-top: 62px;
        .el-button {
          width: 136px;
          margin: 0 16px;
        }
      }

      .no-account-tip-row {
        margin-top: 50px;

        color: #333333;
        line-height: 48px;
        font-size: 16px;
        text-align: center;
      }
    }
  }

  .link {
    color: #3883ff;
    text-decoration: none;
    cursor: pointer;
  }
}
</style>